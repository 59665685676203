import React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import { Card } from "antd";
import page from "../components/page";
import HeaderMenu from "../components/HeaderMenu";
import Container from "../components/Container";
import { Title, Text } from "../components/typography";
import { pY, mY } from "../components/spacingShorthands";

export const query = graphql`
  {
    allMarkdownRemark(sort: { fields: frontmatter___createdOn, order: DESC }) {
      nodes {
        frontmatter {
          slug
          title
          createdOn
        }
      }
    }
  }
`;

export default page(
  ({
    data: {
      allMarkdownRemark: { nodes },
    },
  }) => (
    <Container css={pY(64)}>
      <Helmet>
        <title>Latest news</title>
      </Helmet>
      <Title>Lastest news</Title>
      {nodes.map(({ frontmatter: { slug, createdOn, title } }) => (
        <Link
          key={slug}
          to={`/blog/p/${slug}`}
          css={{ ...mY(48), display: "block" }}
        >
          <Card hoverable>
            <Title level={4}>{title}</Title>
            <Text type="secondary">
              on {new Date(createdOn).toDateString()}
            </Text>
          </Card>
        </Link>
      ))}
    </Container>
  ),
  { HeaderMenu }
);
